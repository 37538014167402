import axios from 'axios';
import APIUtils from './APIUtils';
import LanguageDesignationUtils, {
  LanguageDesignation,
} from './LanguageDesignationUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';
import ProvinceUtils, { Province } from './ProvinceUtils';
import SectorsOfCareUtils, { SectorOfCare } from './SectorsOfCareUtils';
import HealthTeamUtils, { HealthTeam } from './HealthTeamUtils';
import ServiceUtils, { Service } from './ServiceUtils';
import VerificationUtils, { Verification } from './VerificationUtils';

interface ProviderCategory {
  key: string;
  name_long: LanguageObject;
  name_short: LanguageObject;
}

interface Provider {
  id: number;
  verification: Partial<Verification>;
  name_long: LanguageObject;
  name_short: LanguageObject;
  description: LanguageObject;
  categories: ProviderCategory[];
  uri?: LanguageObject;
  phone_number?: string;
  language_designation?: Partial<LanguageDesignation>;
  province?: Partial<Province>;
  sectors_of_care?: Partial<SectorOfCare>[];
  health_teams?: Partial<HealthTeam>[];
  services?: Partial<Service>[];
  no_of_pos: number;
}

const provider = {
  generateDefault: (item?: Partial<Provider>): Partial<Provider> => {
    return {
      id: item?.id,
      verification: VerificationUtils.generate(item?.verification),
      name_long: LocaleUtils.langobj.generate(item?.name_long),
      name_short: LocaleUtils.langobj.generate(item?.name_short),
      description: LocaleUtils.langobj.generate(item?.description),
      categories: item?.categories || [],
      uri: LocaleUtils.langobj.generate(item?.uri),
      phone_number: item?.phone_number,
      language_designation:
        LanguageDesignationUtils.languageDesignation.generateDefault(
          item?.language_designation
        ),
      province: ProvinceUtils.province.generateDefault(item?.province),
      sectors_of_care: (item?.sectors_of_care || [])?.map((value) =>
        SectorsOfCareUtils.sectorOfCare.generateDefault(value)
      ),
      health_teams: (item?.health_teams || [])?.map((value) =>
        HealthTeamUtils.healthTeam.generateDefault(value)
      ),
      services: (item?.services || []).map((value) =>
        ServiceUtils.service.generateDefault(value)
      ),
      no_of_pos: item?.no_of_pos,
    };
  },
};

const category = {
  generateDefault: (
    item?: Partial<ProviderCategory>
  ): Partial<ProviderCategory> => {
    return {
      key: item?.key,
      name_long: LocaleUtils.langobj.generate(item?.name_long),
      name_short: LocaleUtils.langobj.generate(item?.name_short),
    };
  },
};

const api = {
  list: async () => {
    const resp = await axios.get<Provider[]>('serviceProvider/list.php');
    return resp.data;
  },
  listConcise: async () => {
    const resp = await axios.get<Provider[]>(
      'serviceProvider/list-concise.php'
    );
    return resp.data;
  },
  get: async (id: number) => {
    const resp = await axios.get<Provider>('serviceProvider/get.php', {
      params: { id: id },
    });
    return resp.data;
  },
  create: async (item: Partial<Provider>) => {
    const resp = await axios.post<Provider>('serviceProvider/create.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  update: async (item: Partial<Provider>) => {
    const resp = await axios.put<Provider>('serviceProvider/update.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  remove: async (id: number) => {
    const resp = await axios.delete<Provider>('serviceProvider/remove.php', {
      params: { id: id },
    });
    return resp.data;
  },
  verify: async (id: number, status: boolean) => {
    return await VerificationUtils.verify(
      'serviceProvider/verify.php',
      status,
      {
        id: id,
      }
    );
  },

  category: {
    list: async () => {
      const resp = await axios.get<ProviderCategory[]>(
        'serviceProvider/category/list.php'
      );
      return resp.data;
    },
    get: async (key: string) => {
      const resp = await axios.get<ProviderCategory>(
        'serviceProvider/category/get.php',
        {
          params: { key: key },
        }
      );
      return resp.data;
    },
    create: async (item: Partial<ProviderCategory>) => {
      const resp = await axios.post<ProviderCategory>(
        'serviceProvider/category/create.php',
        {
          ...APIUtils.clean(item),
        }
      );
      return resp.data;
    },
    update: async (item: Partial<ProviderCategory>) => {
      const resp = await axios.put<ProviderCategory>(
        'serviceProvider/category/update.php',
        {
          ...APIUtils.clean(item),
        }
      );
      return resp.data;
    },
    remove: async (key: string) => {
      const resp = await axios.delete<ProviderCategory>(
        'serviceProvider/category/remove.php',
        {
          params: { key: key },
        }
      );
      return resp.data;
    },
  },
};

export default { api, provider, category };
export { Provider, ProviderCategory };
