import axios from 'axios';
import APIUtils from './APIUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';
import ProvinceUtils, { Province } from './ProvinceUtils';

interface HealthTeam {
  id: number;
  province: Partial<Province>;
  name_long: LanguageObject;
  name_short: LanguageObject;
}

const healthTeam = {
  generateDefault: (item?: Partial<HealthTeam>): Partial<HealthTeam> => {
    return {
      id: item?.id,
      province: ProvinceUtils.province.generateDefault(item?.province),
      name_long: LocaleUtils.langobj.generate(item?.name_long),
      name_short: LocaleUtils.langobj.generate(item?.name_short),
    };
  },

  api: {
    list: async (params?: { province_key: string }) => {
      const resp = await axios.get<HealthTeam[]>('healthTeam/list.php', {
        params: params,
      });
      return resp.data;
    },
    get: async (id: number | string, province_key?: string) => {
      const resp = await axios.get<HealthTeam>('healthTeam/get.php', {
        params: { id: id, province_key: province_key },
      });
      return resp.data;
    },
    create: async (item: Partial<HealthTeam>) => {
      const resp = await axios.post<HealthTeam>('healthTeam/create.php', {
        ...APIUtils.clean(item),
      });
      return resp.data;
    },
    update: async (item: Partial<HealthTeam>) => {
      const resp = await axios.put<HealthTeam>('healthTeam/update.php', {
        ...APIUtils.clean(item),
      });
      return resp.data;
    },
    remove: async (id: number) => {
      const resp = await axios.delete<HealthTeam>('healthTeam/remove.php', {
        params: { id: id },
      });
      return resp.data;
    },
  },
};

export default { healthTeam };
export { HealthTeam };
