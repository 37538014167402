import axios from 'axios';

interface Verification {
  status: 'verified' | 'not_verified';
  user_id?: number;
  timestamp?: string;
  user_first_name?: string;
  user_last_name?: string;
}

const generate = (item?: Partial<Verification>): Verification => {
  return {
    status: item?.status || 'not_verified',
    user_id: item?.user_id,
    timestamp: item?.timestamp,
    user_first_name: item?.user_first_name,
    user_last_name: item?.user_last_name,
  };
};

const verify = async (url: string, status: boolean, params?: any) => {
  const resp = await axios.put<Verification>(url, {
    status: status,
    ...params,
  });
  return resp.data;
};

export default { generate, verify };
export { Verification };
